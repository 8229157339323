@import "./vars";

/* ======== Angular material custom themes ======== */
@include mat-core($typography-base);
.light-mode {
  @include angular-material-color($theme);
}
.dark-mode {
  @include angular-material-theme($theme-dark);
}

// @media (prefers-color-scheme: light) {
//   @include angular-material-theme($theme);
// }

// @media (prefers-color-scheme: dark) {
//   @include angular-material-theme($theme-dark);
// }
