@import "~@angular/material/theming";
@import "./color";

/* Theming */
$palette-primary: mat-palette($color-dark-purple);
$palette-accent: mat-palette($color-dark-pink);
$theme: mat-light-theme($palette-primary, $palette-accent);

$palette-dark-primary: mat-palette($color-light-pink);
$palette-dark-accent: mat-palette($color-light-purple);
$theme-dark: mat-dark-theme($palette-dark-primary, $palette-dark-accent);

/* Layout */
$unit-spacer: 8px;
$h-nav: 48px;
$h-logo: 28px;
$h-footer: 0;
$mat-elevation: 6;

/* Fonts */
$typography-header: mat-typography-config(
  $font-family: '"Space Grotesk", sans-serif',
);
$typography-base: mat-typography-config(
  $font-family: '"Poppins", sans-serif',
);
