/* Palettes */
$color-dark-pink: (
  50: #faf3f6,
  100: #f4e1e8,
  200: #eccdd9,
  300: #e4b9ca,
  400: #dfaabe,
  500: #d99bb3,
  600: #d593ac,
  700: #cf89a3,
  800: #ca7f9a,
  900: #c06d8b,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffd3e2,
  A700: #ffb9d1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$color-dark-purple: (
  50: #e5e4e7,
  100: #bfbac3,
  200: #958d9b,
  300: #6a5f72,
  400: #4a3c54,
  500: #2a1a36,
  600: #251730,
  700: #1f1329,
  800: #190f22,
  900: #0f0816,
  A100: #a258ff,
  A200: #8625ff,
  A400: #6b00f1,
  A700: #6000d7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$color-light-pink: (
  50: #fbf8fb,
  100: #f6eef4,
  200: #f0e3ed,
  300: #e9d7e5,
  400: #e5cfe0,
  500: #e0c6da,
  600: #dcc0d6,
  700: #d8b9d0,
  800: #d3b1cb,
  900: #cba4c2,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff6fd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$color-light-purple: (
  50: #f6f2f9,
  100: #e9def1,
  200: #dbc9e8,
  300: #ccb3de,
  400: #c1a2d7,
  500: #b692d0,
  600: #af8acb,
  700: #a67fc4,
  800: #9e75be,
  900: #8e63b3,
  A100: #ffffff,
  A200: #f8f0ff,
  A400: #e0bdff,
  A700: #d4a3ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$color-night-pink: (
  50: #e3e4e8,
  100: #b9bbc6,
  200: #8a8ea0,
  300: #5b6179,
  400: #383f5d,
  500: #151d40,
  600: #121a3a,
  700: #0f1532,
  800: #0c112a,
  900: #060a1c,
  A100: #5b6fff,
  A200: #2843ff,
  A400: #001ef4,
  A700: #001bdb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$color-night-purple: (
  50: #e7ebed,
  100: #c2cdd3,
  200: #9aabb6,
  300: #718998,
  400: #527082,
  500: #34576c,
  600: #2f4f64,
  700: #274659,
  800: #213c4f,
  900: #152c3d,
  A100: #7bc3ff,
  A200: #48abff,
  A400: #1594ff,
  A700: #0088fb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
