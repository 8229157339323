@import "https://fonts.googleapis.com/icon?family=Material+Icons+Outlined";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./helpers";
@import "./vars";
@import "./component";

/* Boilerplate */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

/* Font, etc. */
.material-icons {
  font-family: "Material Icons Outlined";
}

/* Globals */
@include ng-scroll-fill;
@include ng-scroll-fix;
@include button-icon-fix;

/* Angular Material Overwrite */
.mat-mini-fab {
  &.mat-accent {
    color: inherit !important;
  }
  span {
    padding-top: 7px !important;
  }
}

.light-mode {
  .mat-fab.mat-accent,
  .mat-mini-fab.mat-accent,
  .mat-expansion-panel,
  .mat-dialog-container .header > *,
  .mat-dialog-container .content {
    background: mat-color($color-dark-pink, 500) !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background: mat-color($color-light-pink, 500);
  }
  &.mat-tooltip {
    color: black;
  }
  .list-card {
    li.link {
      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.dark-mode {
  .mat-fab.mat-accent,
  .mat-mini-fab.mat-accent,
  .mat-expansion-panel,
  .mat-dialog-container .header > *,
  .mat-dialog-container .content {
    background: mat-color($color-dark-purple, 500) !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background: mat-color($color-light-purple, 900);
  }
  &.mat-tooltip {
    color: white;
  }
  .list-card {
    li.link {
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }
}

/* Component Specific */
// Drawer
ui-drawer {
  .ng-scroll-content {
    padding: 0 16px 8px;
  }
}
.mat-drawer {
  // Some Slide in magic
  .slide-in {
    transform: translateX(-120%);
    transition: transform 400ms;
  }
  &.mat-drawer-opened {
    .slide-in {
      transform: translateX(0);

      @for $i from 0 through 20 {
        &:nth-child(#{$i + 1}) {
          transition-delay: 200ms * $i;
        }
      }
    }
  }
  .pop-in {
    transform: scale(0, 0);
    transition: transform 400ms;
  }
  &.mat-drawer-opened {
    .pop-in {
      transform: scale(1, 1);

      @for $i from 0 through 20 {
        &:nth-child(#{$i + 1}) {
          transition-delay: 100ms * $i + 200ms;
        }
      }
    }
  }
}

/* Dialogs */
.window-panel {
  width: 1024px;
  max-width: calc(100vw - #{$unit-spacer * 4}) !important;
  height: 768px;
  max-height: calc(100% - #{$unit-spacer * 4}) !important;
  @include clickthrough-container;

  .mat-dialog-container {
    overflow: visible;
    max-height: 100%;
    padding: 0;
    background: none !important;
    box-shadow: none !important;
    @include clickthrough-container;

    & > * {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: inherit;
      max-height: inherit;
      @include clickthrough-container;
    }

    .header .title,
    .content {
      @include mat-elevation($mat-elevation);
      border-radius: 8px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $unit-spacer;
      @include clickthrough-container;

      .title {
        padding: $unit-spacer $unit-spacer * 2;
        margin: 0;
        @include mat-typography-level-to-styles(
          $typography-header,
          subheading-1
        );
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .content {
      flex: 1;
      overflow: hidden;
    }
  }
}
.transparent-backdrop {
  background: none;
  cursor: crosshair;
}

// Atelier Dialog
.atelier-menu {
  &,
  .mat-dialog-container {
    overflow: visible;
    padding: 0;
    background: none !important;
    box-shadow: none !important;
  }
}

// Media Dialog
.docu-media-panel {
  @extend .window-panel;
}

// Documenton Filter Dialog
.docu-filter-panel {
  @extend .window-panel;
  width: 360px;
  height: unset;

  .mat-dialog-container {
    height: inherit;
  }
}

// Archive Dialog
.archive-panel {
  @extend .window-panel;

  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      margin-top: 0;
    }
    .mat-form-field-outline {
      border-radius: 5px;
      top: 0;
    }
    .mat-form-field-label {
      margin-top: 0;
    }
    .mat-form-field-infix {
      border-top: none;
      padding: 0.75em 0;
    }
  }
}

// Personnel Dialog
.personnel-panel {
  @extend .window-panel;
  width: 480px;
  height: unset;

  .mat-dialog-container {
    height: inherit;
  }
}

/* Tooltip */
.tooltip {
  font-size: 15px !important;
  font-weight: 500;
  margin: 0 $unit-spacer !important;
  padding: 0;
}
