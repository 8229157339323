@import "./vars";
@import "./atomic";

/* Basic Button */
.button {
  cursor: pointer;
  padding: 0 $unit-spacer * 2;

  border: 0;
  border-radius: 4px;
  outline: none;

  line-height: 36px;
  font-family: Favorit, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
}

/* Cards */
.list-card {
  // background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  @include mat-elevation($mat-elevation);

  &.disabled {
    &,
    & * {
      color: $black-12-opacity !important;
      pointer-events: none !important;
      cursor: default !important;
    }
  }

  .mat-list-base {
    padding: 0;
  }
  .mat-expansion-panel,
  .mat-list-item {
    margin: 0 0 8px;
    background: none;
    box-shadow: none !important;

    &.mat-expanded {
      margin-bottom: 8px !important;
    }
    span.mat-expansion-indicator {
      line-height: 0 !important;
      margin-right: 12px;
    }
  }
  .mat-expansion-panel-header,
  .mat-list-item {
    padding: 0 16px;
    box-sizing: border-box;
    * {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      @include mat-typography-level-to-styles($typography-header, title);
    }
  }

  p {
    a {
      text-decoration: underline;
      color: blue;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 $unit-spacer * 2;

    li {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: center;

      // height: 36px;
      line-height: 16px;
      padding: 10px 16px 10px 20px;

      // color: $dark-primary-text;
      font-size: 15px;
      font-weight: 400;

      border-radius: 4px;

      &.link {
        cursor: pointer;
      }

      .mat-icon {
        height: 12px;
        width: 12px;
        margin: 0 8px 0 -16px;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .subheader {
      height: unset;
      line-height: 16px;
      padding: 0;

      font-size: 14px;
      font-weight: 500;
      @extend .o-62;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }

  .mat-form-field {
    width: 100%;
  }
}

mat-card.mini-card {
  display: flex;
  flex-direction: row;
  width: 360px;
  height: 120px;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;

  .img-preview {
    min-width: 120px;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .content-preview {
    width: 100%;
    padding: 16px;
    overflow: hidden;
  }

  .mat-card-header {
    .mat-card-header-text {
      margin: 0;

      .mat-card-title {
        margin-bottom: 8px;
      }
      .mat-card-subtitle {
        margin-bottom: 6px;
        text-transform: uppercase;
        @include mat-typography-level-to-styles($typography-header, caption);
      }
    }

    .close {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .mat-card-content {
    max-width: 100%;
    margin: 0;
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
  }

  .mat-card-actions {
    margin-bottom: 0;

    .mat-button {
      padding: 0 8px;
    }
  }
}

/* Quick Link FABs */
.quick-links.mat-toolbar {
  justify-content: center;
  align-items: center;

  height: $h-nav;
  min-height: $h-nav;
  margin: $unit-spacer 0 $unit-spacer;
  padding: 0 $unit-spacer * 3;

  background: none !important;
  @include clickthrough-container;

  .mat-mini-fab {
    @include mat-elevation($mat-elevation);
    .fab {
      font-size: 24px;
      width: unset;
    }

    &:not(:last-child) {
      margin-right: $unit-spacer * 3;
    }
  }
}
