@import "./vars";

/* Helpers */
/* Z Index */
$z-layers: (
  "splash": 9000,
  "modal": 1050,
  "modal-backdrop": 1040,
  "high": 100,
  "mid": 10,
  "default": 0,
  "bottom": -9000,
);

@function z($layer) {
  @return map-get($z-layers, $layer);
}

@mixin z-index($layer) {
  z-index: z($layer);
}

/* Layout */
@mixin fill {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;

  overflow: hidden !important;
}

@mixin footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
}

/* Navigation */
@mixin clickthrough-container {
  pointer-events: none !important;
  * {
    pointer-events: all;
  }
}

// ng Scroll
@mixin ng-scroll-fill {
  .ng-scroll-content {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
}
@mixin ng-scroll-fix {
  .ng-scroll-content {
    display: inline-block;
    vertical-align: top; // or vertical-align: middle;
  }
}

/* Text */
@mixin no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Buttons */
@mixin pill($size) {
  max-height: $size;
  border-radius: $size / 2 !important;
  padding-left: $size / 2 !important;
  padding-right: $size / 2 !important;
}

// Fix for inproper positioning when alternate font is applied
@mixin button-icon-fix() {
  .mat-icon-button,
  .mat-fab,
  .mat-mini-fab {
    .mat-button-wrapper {
      font-family: Roboto, "Helvetica Neue", sans-serif;
    }
  }
}
